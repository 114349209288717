import { css } from 'astroturf';
import React, { Component } from 'react';
import CTA from '../../components/CTA';
import PageHeader from '../../components/PageHeader';
import SEO from '../../components/SEO';
import Link from '../../components/link';
import Layout from '../../layout';

const styles = css`
  .externals {
    & > div {
      display: grid;
      place-items: center;
      border: 1px solid rgba(0, 70, 150, 0.1);
      padding: 2rem;
      border-radius: 0.5rem;
      text-align: center;
      & > * + * {
        padding-top: 1rem;
      }
    }
    & > * + * {
      margin-top: 2rem;
    }

    & h3 {
      margin: 0;
    }

    & h3 a {
      text-decoration: none;
      margin: 0;
      padding: 0;
    }

    & h3 button {
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
      border: 0;
      margin: 0;
      background: none;
    }

    @media (min-width: 50rem) {
      display: flex;

      & > * {
        flex: 1;
      }
      & > * + * {
        margin-top: 0;
        margin-left: 1rem;
      }
    }
  }

  .contactForm {
    overflow: hidden;
    border: 1px solid rgba(0, 70, 150, 0.1);
    padding: 2rem;
    border-radius: 0.5rem;

    @media (min-width: 50rem) {
      padding: 4rem;
    }

    & > div {
      position: relative;
      max-width: 40rem;
      margin: 0 auto;
    }
    & > div > * {
      display: block;
    }

    & legend {
      font-size: 1.5rem;
      color: #107db5;
      font-weight: 400;
    }

    & label {
      margin: 2rem 0 0.5rem;
    }

    & input,
    & textarea {
      font-size: 1rem;
      padding: 0.5rem 1rem;
      border-radius: 0.25rem;
      border: 1px solid rgba(0, 70, 150, 0.4);
      width: 100%;
    }

    & textarea {
      height: 8rem;
    }

    & button {
      margin-top: 2rem;
      float: right;
      clear: right;
      border: 0;
      border-radius: 8px;
      padding: 0 1rem;
      line-height: 2rem;
      background: #107db5;
      font-weight: 600;
      font-size: 1.17rem;
      color: #fff;
      transition: 0.25s ease-in-out background;

      &:hocus {
        background: #67b26f;
      }
    }
    & small {
      opacity: 0.75;
      float: right;
      clear: right;
    }
  }

  .intro {
    text-align: center;
    font-size: 18px;
  }

  .manage {
    background: rgba(0, 70, 150, 0.025);
  }
`;

class Page extends Component {
  constructor() {
    super();

    this.state = {
      segmentLoaded: false,
      mailSent: false,
      message: '',
      status: '',
    };
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const name = e.target.elements[0].value;
    const email = e.target.elements[1].value;
    const msg = e.target.elements[2].value;

    if (name && email && msg) {
      const link = 'https://dashboard.polypane.app/support';
      const data = {
        name,
        email,
        msg,
      };
      const result = await fetch(link, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (result.ok) {
        this.setState({
          mailSent: true,
          status: 'ok',
          message: `<h3>Request sent</h3><p>We'll contact you via e-mail soon.</p>`,
        });
      } else {
        this.setState({
          mailSent: false,
          status: 'error',
          message: `<h3>Request not sent!</h3><p>There was an error sending this form. please try again later.</p>`,
        });
      }
    } else {
      this.setState({
        mailSent: false,
        status: 'error',
        message: `<h3>Request not sent!</h3><p>All form fields are required</p>`,
      });
    }
  };

  resetForm = () => {
    this.setState({
      mailSent: false,
      status: '',
      message: '',
    });
  };

  loadSegment = () => {};

  render() {
    const { mailSent, status, message } = this.state;

    return (
      <Layout>
        <SEO title="Support" keywords={['']} pathname={this.props.location.pathname} />
        <PageHeader>
          <h1>Support</h1>
          <h2>Let us know how we can help</h2>
        </PageHeader>

        <div className="widePage childPadding" onMouseOver={this.loadSegment}>
          <div className={styles.externals}>
            <div className={styles.twitter}>
              <h3>
                <button
                  className="link-like"
                  type="button"
                  onClick={() => {
                    if (typeof window !== 'undefined' && window.$crisp) {
                      window.$crisp.push(['do', 'chat:open']);
                    } else {
                      alert("It seems you're blocking Crisp, our chat provider. Please use one of the other options!");
                    }
                  }}
                >
                  Chat with us
                </button>
              </h3>
              <p>We're happy to help &mdash; Chat with us live!</p>
            </div>
            <div className={styles.slack}>
              <h3>
                <Link to="https://join.slack.com/t/polypane/shared_invite/zt-1h9psk5dz-jAVl3HD91BUnWCqJhdDAlw">
                  Slack
                </Link>
              </h3>
              <p>Join the Polypane community on Slack.</p>
            </div>
            <div className={styles.slack}>
              <h3>
                <Link to="https://twitter.com/polypane">Twitter</Link>
              </h3>
              <p>We frequently share updates on Twitter. </p>
            </div>
          </div>
          <div className={styles.externals}>
            <div className={styles.twitter}>
              <h3>
                <Link to="/docs/">Documentation</Link>
              </h3>
              <p>Learn how to use Polypane to its fullest.</p>
            </div>
            <div className={styles.twitter}>
              <h3>
                <Link to="/docs/faq/">FAQ</Link>
              </h3>
              <p>Your questions answered.</p>
            </div>
            <div className={styles.manage}>
              <h3>
                <Link to="https://dashboard.polypane.app">Manage account</Link>
              </h3>
              <p>Manage subscription, invite team members.</p>
            </div>
          </div>

          <form className={styles.contactForm} onSubmit={this.handleSubmit}>
            {mailSent && status === 'ok' && <div dangerouslySetInnerHTML={{ __html: message }} />}
            {!mailSent && (
              <div>
                <p>
                  <em>You can also send us a message.</em>
                </p>
                <legend>How can we help?</legend>
                <label htmlFor="name">Name *</label>
                <input type="text" defaultValue="" name="name" id="name" required onFocus={this.resetForm} />

                <label htmlFor="email">E-mail *</label>
                <input type="email" defaultValue="" name="email" id="email" required onFocus={this.resetForm} />

                <label htmlFor="msg">Message/Question *</label>
                <textarea name="msg" id="msg" onFocus={this.resetForm} />
                <small>* required</small>

                {status === 'error' && <div dangerouslySetInnerHTML={{ __html: message }} />}

                <button type="submit">Ask question</button>
              </div>
            )}
          </form>
        </div>
        <CTA />
      </Layout>
    );
  }
}

export default Page;
